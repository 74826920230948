<template>
	<transition-fade></transition-fade>
	<div class="app">
		<!--  <pull-to :top-load-method="changeCategory('allDeals')"></pull-to> -->
		
		<!-- menu laterale -->
		<Transition name="slide-fade">
		<aside v-if="sidebarOpen" class="fixed w-64 pt-12 flex-none z-10" aria-label="Sidebar"> 
		<div class="overflow-y-auto py-4 px-3 bg-neutral-900 rounded  ">
			<ul class="space-y-2 w-full text-left h-screen">
				<li>
					<a href="#" class="flex items-center px-2 text-base font-normal text-white rounded-lg  hover:bg-gray-700 w-full" @click="position='allDeals',ricercaOpen = !ricercaOpen,  selectCategoryOpen = false, sidebarOpen=false, settingsIsOpen= false">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
						<button class="block py-2 px-2 text-white rounded mx-2 my-2" > Ricerca</button>
						<span class="inline-flex justify-center items-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full ">Beta</span>
					</a>
				</li>
				<li>
					<a href="#" class="flex items-center px-2 text-base font-normal text-white rounded-lg  hover:bg-gray-700 w-full" @click="inHomeDealsSelection(),ricercaOpen = false, selectCategoryOpen = false, sidebarOpen=false, settingsIsOpen= false">
						<svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><polyline points="5 12 3 12 12 3 21 12 19 12"></polyline><path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path><rect x="10" y="12" width="4" height="4"></rect></svg>
						<button class="block py-2 px-2 text-white rounded mx-2 my-2" > Home </button>
					</a>
				</li>
				<li>
					<a href="#" class="flex items-center px-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-700 w-full" @click="selectCategoryOpen = !selectCategoryOpen, ricercaOpen = false, settingsIsOpen= false">
						<svg aria-hidden="true" class="flex-shrink-0 w-6 h-6 text-white transition duration-75 group-hover:text-gray-900 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path></svg>
					<button class="block py-2 px-2 text-white rounded mx-2 my-2 w-full"> Categorie</button>
					</a>

					<!-- menu categorie-->
					<transition-expand>
					<div v-show="selectCategoryOpen" id="dropdown" class=" z-10 w-auto bg-gray-700 rounded divide-y divide-gray-700 shadow">
						<ul class="overflow-y-auto py-1 h-56 text-gray-200 divide-y divide-gray-600">
						<button v-if="categorySelected=='allDeals'" class="bg-gray-800 block py-2 px-4 text-gray-200  text-left w-full"
						@click="changeCategory('allDeals'), selectCategoryOpen=false, sidebarOpen=false">Tutte</button>
						<button v-if="categorySelected!='allDeals'" class="block py-2 px-2 text-gray-200 text-left w-full"
						@click="changeCategory('allDeals'), selectCategoryOpen=false, sidebarOpen=false">Tutte</button>
						<div v-for="category in categoryList" :key="category">
							<button v-if="categorySelected==category" class="bg-gray-800 block py-2 px-2 text-gray-200 text-left w-full"
								@click="changeCategory(category), selectCategoryOpen=false, sidebarOpen=false">{{category}}</button>
							<button v-if="categorySelected!=category" class="block py-2 px-2 text-gray-200 text-left w-full"
								@click="changeCategory(category), selectCategoryOpen=false, sidebarOpen=false">{{category}}</button>
						</div>
						</ul>
					</div>
					</transition-expand>
				</li>

				<li>
					<a href="#" class="flex items-center px-2 text-base font-normal text-white rounded-lg  hover:bg-gray-700 w-full">
						<svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings text-white"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
						<button class="block py-2 px-2 text-white rounded mx-2 my-2" @click="settingsIsOpen = true, ricercaOpen= false,  selectCategoryOpen = false, sidebarOpen=false"> Impostazioni</button>
					</a>
				</li>

				<li>
					<a href="/blog" class="flex items-center px-2 text-base font-normal text-white rounded-lg  hover:bg-gray-700 w-full">
						<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path fill="white" d="M160-120q-33 0-56.5-23.5T80-200v-640l67 67 66-67 67 67 67-67 66 67 67-67 67 67 66-67 67 67 67-67 66 67 67-67v640q0 33-23.5 56.5T800-120H160Zm0-80h280v-240H160v240Zm360 0h280v-80H520v80Zm0-160h280v-80H520v80ZM160-520h640v-120H160v120Z"/></svg>						<button class="block py-2 px-2 text-white rounded mx-2 my-2"> Blog</button>
					</a>
				</li>

				<li v-if="installable">
					<a href="#" class="flex items-center px-2 text-base font-normal text-white rounded-lg  hover:bg-gray-700 w-full" @click="installButtonTriggered()">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="white" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path><polyline points="7 11 12 16 17 11"></polyline><line x1="12" y1="4" x2="12" y2="16"></line></svg>						
						<button class="block py-2 px-2 text-white rounded mx-2 my-2"> Installa l'app </button>
					</a>
				</li>

				<li>
					<div href="#" class="flex items-center px-2 text-base font-normal text-white rounded-lg w-full text-center ">
						<div class="inline-flex justify-center items-center px-2  text-sm font-medium text-gray-900 bg-gray-500 rounded-full text-center "> Server ID: POSRV{{serverIdNumber}}</div> 
					</div>
				</li>
			</ul>
		</div>
		</aside>
		</Transition>

		<nav class="fixed top-0 left-0 right-0 bg-neutral-900 z-10">
			<div class="flex justify-between ">
				<div class="flex" @click="sidebarOpen = !sidebarOpen" >
					<div class="block py-2 px-2 text-white bg-purple-700 rounded mx-2 my-2 ">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" /></svg>
					</div>
				</div>
				<div v-if="ricercaOpen" class="flex justify-between mx-auto place-items-center w-fit h-fit ">  <!-- Ricerca prodotto -->
					<input type="text" v-on:keyup.enter="ricercaProdotto(keyWordsSearch)" v-model="keyWordsSearch"
						class=" px-10 m-2 py-2 w-5/6  text-gray-900 bg-gray-50 rounded-lg"
						placeholder="Ricerca un prodotto...">
					<svg xmlns="http://www.w3.org/2000/svg" @click="ricercaProdotto(keyWordsSearch)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
				</div>
				<div v-if="!ricercaOpen" class="flex justify-between mx-auto place-items-center ">  <!-- Titolo inizio pagina -->
					<img class=" w-14 h-14" src="https://i.imgur.com/zzRTDfw_d.webp?maxwidth=760&fidelity=grandf" />
					<p class="text-3xl text-white font-semibold m-2">piovonoofferte</p>
				</div>
			</div>
		</nav>
		<!--

			
		-->

		<transition-expand>
		<div v-if="iosInstallMessagePopUp" class="fixed z-20 rounded-xl object-centre justify-between bg-neutral-900 text-white m-8 mt-20 opacity-95">
			<h1 class="text-xl font-bold underline m-2">Per installare l'app su iOS</h1>
			<h1 class="text-m font-medium m-2">Tocca il <b>pulsante Condividi</b>, scorri l'elenco di opzioni, quindi <b>tocca “Aggiungi alla schermata Home”</b>.</h1>
			<button class="py-2 px-4 text-white bg-purple-700 rounded mx-2 my-2" @click="iosInstallMessagePopUp = false">OK</button>
		</div>
		</transition-expand>

		<div v-if="!settingsIsOpen" >
		<pull-refresh v-model="isRefreshing" pulling-text="" loosing-text="" success-text="" head-height="70" pull-distance="100" @refresh="refresh()">
			<div class="grid place-items-center"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-up animate-bounce" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="12" y1="5" x2="12" y2="19"></line><line x1="18" y1="11" x2="12" y2="5"></line><line x1="6" y1="11" x2="12" y2="5"></line></svg></div>
			<div class="m-2 pt-6">


			<!-- promozione installa app messaggio -->
			<transition-expand>
							<button v-show="installable && position!='singleview' && position!='blog'" class="buttonDeal w-full bg-purple-100 rounded-xl mt-1" @click="installButtonTriggered()">
								<div class="flex justify-between p-2 m-1 ">
									<img class=" object-centre object-contain h-40 w-40 bg-white m-1 rounded-xl p-4 mt-2" src="https://i.imgur.com/zzRTDfw_d.webp?maxwidth=760&fidelity=grandf" />
									<div class="pt-6 md:p-8 text-center w-full">
										<p class="text-xl font-bold">
											INSTALLA L'APP
										</p>
										<p class="text-m"> Tieni tutte le offerte a portata di mano </p>
										<div class="mt-1">
											<p class="text-slate-700 text-xs">Pesa meno di un paio di foto! Ed è piena di convenienza!</p>
											<button class="py-2 px-4 text-white bg-purple-700 rounded-xl mx-1 my-1">Installa ora</button>
										</div>
									</div>
								</div>
							</button>
			</transition-expand>
			<div v-if="position=='singleview'">
				<singleView :asin="singleProductSelected"></singleView>
			</div>
			<div v-if="position=='blog'">
				<blog></blog>
			</div>
			<div v-if="position=='home'" > <!-- home page -->
				<p class="text-3xl text-gray-900 font-semibold m-2">Le migliori offerte:</p>
			</div>
			<div class="mt-2 lg:columns-2"> <!-- due colonne se dispositivo ha uno schermo lg-->
				<div v-for="(deal, index) in deals" :key="index">
					
					<div v-if="categorySelected =='allDeals' || deal.category == categorySelected || position=='home'" class="break-inside-avoid-column mx-1">
						<lazy-component>
							<!-- opened post -->
							<transition-expand>
							<div v-show="dealSelected == deal.asin" class="modal fade">
								<figure class=" justify-between bg-slate-100 rounded-xl my-1 object-centre object-contain">
									<div v-if="position=='home'" class="pt-1" @click="position='allDeals', changeCategory(deal.category)"> <!-- home page -->
										<p class=" text-left text-xl text-gray-900 font-semibold mx-2 my-1">Categoria: {{ deal.category }}</p>
										<div class="border-t-2 border-gray-300 mx-2"></div>
									</div>
									<div class=" relative">
										<button type="button" @click="dealSelected=''" class=" absolute top-0 right-0 bg-white h-6 w-6 rounded-full mt-1 mr-1 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										<svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
										</svg>
										</button>
									</div>

									<div class="2-full grid place-items-center">
										<img class=" object-contain h-64 w-5/6 bg-white m-2 rounded-xl p-4 " :src="deal.image" @click="openToAmazon(deal)" :href="'https://amazon.it/dp/'+deal.asin+'?tag=ronchitom_21'"/>
									</div>
									<div class="pt-6 md:p-8 text-center w-full">
										<p class="text-xl font-medium m-2" @click="openToAmazon(deal)" :href="'https://amazon.it/dp/'+deal.asin+'?tag=ronchitom_21'" >
											{{ deal.title }}
										</p>
										<div class="mt-1">
											<div class="grid grid-cols-1 place-content-center place-items-center">
													<div class="grid grid-cols-2 w-fit place-items-center place-content-center">
														<s class=" text-slate-700 w-fit mx-1">
															{{ deal.noDealPrice.toFixed(2) }}€
														</s>
														<p class="text-white bg-red-600 rounded w-fit px-1 mx-1 text-sm">
															-{{ parseInt(100-(deal.price*100)/deal.noDealPrice) }}%
														</p>
													</div>
											</div>
											<p class="text-sky-500">{{ deal.price.toFixed(2) }}€</p>

											<h1 v-if="deal.hasCoupon" class="text-slate-500">
												Coupon: {{ deal.coupon }}
											</h1>
											<div v-if="dealSelected == deal.asin">
												<chartPrice :serverDomain="serverDomain" :asin="deal.asin"></chartprice>
											</div>
											<div class="flex my-2 place-content-center">
												<h1 class="text-slate-500 mx-5 text-xs">
													Categoria: {{ deal.category }}
												</h1>
												<h1 class="text-slate-500 mx-5 text-xs">
													Ultima verifica: {{ deal.lastCheck }}
												</h1>
											</div>
											<div :class="{'flex justify-center':webShareApiSupported()}">
												<button
													class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full m-2"
													@click="openToAmazon(deal)" :href="'https://amazon.it/dp/'+deal.asin+'?tag=ronchitom_21'">Acquista ora
												</button>
												<button v-if="webShareApiSupported()"  class="bg-gray-200 hover:bg-gray-300 rounded-full text-white m-2" @click="shareViaWebShare(deal.asin)">
													<svg class="m-1" xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30"><path d="M727-80q-47.5 0-80.75-33.346Q613-146.693 613-194.331q0-6.669 1.5-16.312T619-228L316-404q-15 17-37 27.5T234-366q-47.5 0-80.75-33.25T120-480q0-47.5 33.25-80.75T234-594q23 0 44 9t38 26l303-174q-3-7.071-4.5-15.911Q613-757.75 613-766q0-47.5 33.25-80.75T727-880q47.5 0 80.75 33.25T841-766q0 47.5-33.25 80.75T727-652q-23.354 0-44.677-7.5T646-684L343-516q2 8 3.5 18.5t1.5 17.741q0 7.242-1.5 15Q345-457 343-449l303 172q15-14 35-22.5t46-8.5q47.5 0 80.75 33.25T841-194q0 47.5-33.25 80.75T727-80Zm.035-632Q750-712 765.5-727.535q15.5-15.535 15.5-38.5T765.465-804.5q-15.535-15.5-38.5-15.5T688.5-804.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm-493 286Q257-426 272.5-441.535q15.5-15.535 15.5-38.5T272.465-518.5q-15.535-15.5-38.5-15.5T195.5-518.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5Zm493 286Q750-140 765.5-155.535q15.5-15.535 15.5-38.5T765.465-232.5q-15.535-15.5-38.5-15.5T688.5-232.465q-15.5 15.535-15.5 38.5t15.535 38.465q15.535 15.5 38.5 15.5ZM727-766ZM234-480Zm493 286Z"/></svg>
												</button>
											</div>
										</div>
									</div>
								</figure>
							</div>
							</transition-expand>
			
							<!-- standard post -->
							<transition-expand>
							
							<button v-show="dealSelected != deal.asin" class="buttonDeal w-full" @click="goToDeal(deal)">
								<div class="bg-slate-100 rounded-xl p-2 my-1">
									<div v-if="position=='home'" class="pt-1" @click="position='allDeals', changeCategory(deal.category)"> <!-- home page -->
										<p class=" text-left text-xl text-gray-900 font-semibold mx-2">Categoria: {{ deal.category }}</p>
										<div class="border-t-2 border-gray-300 mx-2"></div>
									</div>
									<div class=" flex justify-between bg-slate-100 rounded-xl">
										<img class="object-centre object-contain h-32 w-32 bg-white m-2 rounded-xl p-4" :src="deal.image" />
										<div class="pt-6 md:p-8 text-center w-full">
											<p class="text-lg font-medium">
												{{ deal.title }}
											</p>
											<div class="mt-1">
												
												<div class="grid grid-cols-1 place-content-center place-items-center">
													<div class="grid grid-cols-2 w-fit place-items-center place-content-center">
														<s class=" text-slate-700 w-fit mx-1">
															{{ deal.noDealPrice.toFixed(2) }}€
														</s>
														<p class="text-white bg-red-600 rounded w-fit px-1 mx-1 text-sm">
															-{{ parseInt(100-(deal.price*100)/deal.noDealPrice) }}%
														</p>
													</div>
												</div>
												<p class="text-sky-500">{{ deal.price.toFixed(2) }}€</p>
												
												<h1 v-if="deal.hasCoupon" class="text-sky-500">
													🎫
												</h1>
											</div>
										</div>
									</div>
								</div>
							</button>
						</transition-expand>
						</lazy-component>
					</div>
				</div>
			</div>
			<div v-if="dealsIsAll">
				<p class="text-xl text-gray-900 font-semibold m-2">Sei arrivato alla fine delle offerte!</p>
			</div>
			
			<div v-if="loading" class="p-5 z-0">
				<svg role="status"
					class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-purple-600 mt-6"
					viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
						fill="currentColor" />
					<path
						d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
						fill="currentFill" />
				</svg>
			</div>
		</div>
	</pull-refresh>
</div>

	<div v-if="settingsIsOpen" class="p-5 z-0">  <!-- impostazioni -->
				<transition-expand>
					<div class=" justify-center h-full w-full mt-12">
						<div class="flex justify-center w-full">		
							<div>
								<h1 class="text-l font-bold underline m-2">Promemoria giornaliero offerte:</h1>
								<h1 class="text-xs m-2">Ricevi una notifica una volta al giorno che ti ricorda di controllare le offerte</h1>
							</div>			
							<div class="m-2">
								<label for="default-toggle" class="inline-flex relative items-center cursor-pointer"> <!-- switch on of notifiche -->
									<input type="checkbox" v-model="v" v-bind:checked="notificationReminderDealsState" value="" id="default-toggle" class="sr-only peer" @change="activeOrNotReminderNotification(v)">
									<div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
								</label>
							</div>
						</div>
							<div v-if="notificationReminderDealsState" class="grid-cols-2 grid-rows-3">
								<button class="py-2 px-4 text-white bg-blue-700 rounded-xl mx-1 my-1" @click="()=>{if(timeNotification.indexH<23){timeNotification.indexH++}; updateDealReminderNotifyHour()}">+</button>
								<button class="py-2 px-4 text-white bg-blue-700 rounded-xl mx-1 my-1" @click="()=>{if(timeNotification.indexM<5){timeNotification.indexM++}; updateDealReminderNotifyHour()}">+</button>
								<div class="py-2 px-4 text-black bg-white-700 rounded-xl mx-1 ">{{ timeNotification.h[timeNotification.indexH]}} : {{ timeNotification.min[timeNotification.indexM] }}</div>
								<button class="py-2 px-4 text-white bg-blue-700 rounded-xl mx-1" @click="()=>{if(timeNotification.indexH>0){timeNotification.indexH--}; updateDealReminderNotifyHour()}">-</button>
								<button class="py-2 px-4 text-white bg-blue-700 rounded-xl mx-1" @click="()=>{if(timeNotification.indexM>0){timeNotification.indexM--}; updateDealReminderNotifyHour()}">-</button>
							</div>
						<div class="border-t border-gray-300 m-2"></div>
						<p class="text-xs font-medium m-2 text-gray-900 text-center" @click="inviaNotificaReminder()" > In qualità di Affiliato Amazon io ricevo una percentuale dagli acquisti idonei </p>
					</div>
				</transition-expand>
			</div>

	<a class="invisible" href="https://www.web-stat.com">
	<img class="invisible" alt="Web-Stat analytics" src="https://app.ardalio.com/7/3/2115314.png">
	</a>

	</div>
</template>

<script>
import PullRefresh from 'pull-refresh-vue3'
import MobileDetect from 'mobile-detect'
import { TransitionFade } from '@morev/vue-transitions';
import { TransitionExpand } from '@morev/vue-transitions';
import chartPrice from "./chart.vue";
import { Capacitor } from '@capacitor/core';
import singleView from "./productView.vue";
import blog from "./blog/allpost.vue"

export default {
	name: "AllDeals",
	components: {
		PullRefresh,
		TransitionFade,
		TransitionExpand,
		chartPrice,
		singleView,
		blog
	},
	data() {
		return {
			onTestApp:false, //variable for test
			position: "allDeals", // inizializzo prima ad allDeals e dopo nel mount imposto in home
			sidebarOpen:false, 
			deals:[],
			lastRequestDealsLimitNumber:0,
			nextDealsWasRequested:false,
			dealSelected: '',
			ricercaOpen:false,
			selectCategoryOpen: false,
			categorySelected:"allDeals",
			loading:false,
			isRefreshing:false,
			iosInstallMessagePopUp:false,
			mobileDevice: new MobileDetect(navigator.userAgent),
			installable: false, //in service worker check inizialization set false for not show install button
			isNative:false,
			timeNotification:{
				h:["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"],
				indexH:19,
				min:["00","10","20","30","40","50"],
				indexM:0
			},
			settingsIsOpen:false,
			isFirsOpen:false,
			notificationReminderDealsState:false,
			dealsIsAll:false, //variabile che mostra la scritta "sei arrivato alla fine delle offerte"
			singleProductSelected:(new URLSearchParams(window.location.search)).get('product'),
		};
	},
	props: {
		categoryList : Object,
		serverDomain : String,
		serverIdNumber: Number
	},
	mounted(){
		console.log(window.location.href)
		//this.mobileDevice.userAgents().indexOf('Safari')!=-1 for detect if browser is Safari, works only  for mobile
		//this.loadNextDeals();
		addEventListener('scroll', () => {this.checkIfNeedLoadDeals()});

		if (!('serviceWorker' in navigator)){  //service worker check inizialization
		this.installable = false;
		}else{
			this.installable = true;
		}
		if(this.mobileDevice.userAgents().indexOf('Safari')!=-1){ // se il device è iOS allora l'app è installabile con un altra configurazione perchè il service worker al momento non funziona (guardare le api di safari)
			this.installable = true;
		}
		//script per verificare se è già una pwa o se è installata
		if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches) {
			this.installable = false;
		}

		if(Capacitor.isNativePlatform()){
			this.installable = false;
			this.isNative = true;
		}

		if (localStorage.getItem("isFirstTime")==undefined) {  //Se è la prima volta che apre l'app allora aggiungo una notifica di controllo offerte alle 19
			localStorage.setItem("isFirstTime", false)
			this.isFirsOpen = true;
			this.setHourNotificatioReminder("19")
			this.setMinNotificatioReminder("00")
			localStorage.setItem("nextReminderTimeDay",new Date().getDate()) //  imposto il giorno corrente come prossimo per le notifiche 
			if(this.installable){  //se è in modalità sito non attivo le notifiche default
				this.activeOrNotReminderNotification(false)
			}else{  //se è app allora le attivo di default
				this.activeOrNotReminderNotification(true)
			}
			console.log("nuovo login")
		}else{  // assegno i valori alle variabili
			this.timeNotification.indexH = this.timeNotification.h.indexOf(localStorage.getItem("hourCheckDealsReminder"))
			this.timeNotification.indexM = this.timeNotification.min.indexOf(localStorage.getItem("minutesCheckDealsReminder"))
			this.notificationReminderDealsState = Boolean(localStorage.getItem("reminderNotificationActivate"))//localStorage.getItem("reminderNotificationActivate")=="true"?true:false
			localStorage.setItem("nextReminderTimeDay",new Date().getDate()) //  imposto il giorno corrente come prossimo per le notifiche 
		}
		
		setInterval(() => {  //scheduling notifiche 
			
			const startTime = new Date(); startTime.setHours(this.timeNotification.h[this.timeNotification.indexH], this.timeNotification.min[this.timeNotification.indexM]); startTime.setDate(localStorage.getItem("nextReminderTimeDay"));  //possibile bug? 
			const now = new Date();

			if (startTime.getTime() < now.getTime()) {  //verifico che il tempo attuale sia maggiore del tempo della notifica e invio la notifica, poi aggiorno il prossimo giorno della notifica con il giorno dopo
				this.inviaNotificaReminder()
				localStorage.setItem("nextReminderTimeDay", new Date().getDate()+1)
			}

		}, 1000*10)  //check ogni 10 secondi

		//switchSingleProduct
		//console.log((new URLSearchParams(window.location.search)).get('product'))
		//console.log(this.singleProductSelected)
		if(this.singleProductSelected!=null){
			this.position='singleview'
		}else if(window.location.href.includes("/blog")){
			this.position='blog'
		}else{
			this.inHomeDealsSelection() // all'apertura dell'app apro la home page
		}
	},
	methods: {
		refresh(){
			if(this.position == 'blog'){ 
				this.isRefreshing = false
				return
			}
			this.isRefreshing = true
			if(this.position!='singleview' ){
				if(this.position!="ricerca"){
					this.deals = [];
					this.lastRequestDealsLimitNumber = 0;
					this.dealsIsAll=false;
					this.loadNextDeals()
				}else{
					//conviene aggiornare se è una ricerca?
				}
			}
			this.isRefreshing = false
		},
		inHomeDealsSelection(){  // seleziono solo un prodotto per categoria per la pagina home
			this.lastRequestDealsLimitNumber = 0;
			this.dealsIsAll=false;
			this.categorySelected = ""  //senno mi carica tutte le offerte
			this.position='home'
			this.deals = [];
			this.loadNextDeals()
			let singleDealsList = []
			let categoriesInserted = []
			for (const i in this.deals) {
				if(categoriesInserted.indexOf(this.deals[i].category)==-1){
					singleDealsList.push(this.deals[i])
					categoriesInserted.push(this.deals[i].category)
				}
			}
			this.deals = singleDealsList
		},
		installButtonTriggered(){
			if(this.mobileDevice.userAgents().indexOf('Safari')!=-1){  // se il device è iOS allora mostro le istruzioni per installarlo
				this.sidebarOpen=false
				this.iosInstallMessagePopUp=true
			}
			this.$root.buttonInstallAppClicked()
		},
		changeCategory(category){
			this.position='allDeals'
			//console.log(this.position)
			this.categorySelected = category;
			this.deals = [];
			this.lastRequestDealsLimitNumber = 0;
			this.dealsIsAll=false;
			this.loadNextDeals()
		},
		goToDeal(deal) {
			this.dealSelected = deal.asin;
		},
		openToAmazon(deal){
			window.open(`https://www.amazon.it/dp/${deal.asin}/?tag=ronchitom-21`, '_blank');
		},
		ricercaProdotto(keyWords){
			this.position = "ricerca"
			this.categorySelected = "allDeals"
			//console.log(keyWords)
			if(this.loading == true){
				return
			}
			//console.log("caricamemto...")
			this.deals = []
			this.loading = true
			this.$axios
			.get(`${this.serverDomain}/searchDeals?keyword=${keyWords}&limit=50`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals
			.then((response) => {
				//console.log(this.deals);
				this.isLoading = false
				//console.log(response.data.deals);
				this.deals = response.data.deals
				//console.log(this.deals);
				this.formatText()
			})
			.catch((error) => {
				console.log(error);
				this.errored = true;
			})
			.finally(() => {this.loading = false});
		},
		loadNextDeals(){
			if(this.loading == true || this.dealsIsAll == true){
				return
			}
			//console.log("caricamemto...")
			this.loading = true
			let url = ""
			if (this.categorySelected == "allDeals"){
				url = `${this.serverDomain}/dealsInRange?startRange=${this.lastRequestDealsLimitNumber}&stopRange=${this.lastRequestDealsLimitNumber+100}`
			}else if(this.position=='home'){
				url = `${this.serverDomain}/getBestDealsByCategory`
			}else{
				url = `${this.serverDomain}/dealsInRangeWithFilter?startRange=${this.lastRequestDealsLimitNumber}&stopRange=${this.lastRequestDealsLimitNumber+100}&category=${this.categorySelected}`
			}

			this.$axios
			.get(url, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals
			.then((response) => {
				//console.log(this.deals);
				this.isLoading = false
				if(response.data.deals.length == 0){ //se non ci sono più offerte
					this.dealsIsAll=true
				}
				this.deals = this.deals.concat(response.data.deals)
				//console.log(this.deals);
				this.formatText()
			})
			.catch((error) => {
				console.log(error);
				this.errored = true;
			})
			.finally(() => {this.loading = false; 
			this.lastRequestDealsLimitNumber+=100;
			this.nextDealsWasRequested = false});
		}, 

		checkIfNeedLoadDeals(){
			if(this.position=="ricerca"||this.position=="home"||this.position=='singleview'||this.position=='blog'){return} // se sono in queste posizioni non carico altro
			function getScrollPercent() {
				var h = document.documentElement, 
				b = document.body,
				st = 'scrollTop',
				sh = 'scrollHeight';
				return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
			}	

			if(getScrollPercent() > 97 && this.nextDealsWasRequested == false && this.settingsIsOpen== false && this.position!='home'){
				//console.log(getScrollPercent())
				this.nextDealsWasRequested = true
				this.loadNextDeals()
			}
		},

		formatText(){
			this.deals.forEach(element => {
				if (element.title.length > 60){
					element.title = element.title.slice(0,60) + "..."
				}
			});
		},
		setHourNotificatioReminder(h){
			localStorage.setItem("hourCheckDealsReminder", h);
		},
		setMinNotificatioReminder(m){
			localStorage.setItem("minutesCheckDealsReminder", m);
		},
		updateDealReminderNotifyHour(){
			this.setHourNotificatioReminder(this.timeNotification.h[this.timeNotification.indexH])
			this.setMinNotificatioReminder(this.timeNotification.min[this.timeNotification.indexM])
		},
		activeOrNotReminderNotification(v){  //attiva o disattiva le notifiche di promemoria
			localStorage.setItem("reminderNotificationActivate", v);
			this.notificationReminderDealsState = v
			//console.log(v)
			if(this.notificationReminderDealsState && Notification.permission != "granted"){ // richiedo il permesso per inviare notifiche se non lo ho già
				Notification.requestPermission();
			}

			if(this.notificationReminderDealsState && this.isNative){ // richiedo il permesso per inviare notifiche se non lo ho già
				window.cordova.plugins.notification.local.requestPermission(function (granted) {console.log(granted)});
			}
		},
		inviaNotificaReminder(){
			if(!this.notificationReminderDealsState){return}
			const greeting = new Notification("É arrivata l'ora delle offerte!",{
				body: 'Clicca per controllare le offerte di oggi e risparmiare!',
				icon: 'https://i.imgur.com/zzRTDfw_d.webp?maxwidth=760&fidelity=grandf'
			});
			greeting.addEventListener('click', function(){
				window.open('/');
			});
			
			if(this.isNative){
				window.cordova.plugins.notification.local.schedule({
					title: "É arrivata l'ora delle offerte!",
					text: 'Clicca per controllare le offerte di oggi e risparmiare!',
					foreground: true
				});
			}
		},
        shareViaWebShare(asin) {
            navigator.share({
                url: `https://piovonoofferte.it/?product=${asin}`
            })
        },
        webShareApiSupported() {
            return navigator.share
        }
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}
.slide-fade-enter-active {
  transition: all 0.2s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-320px);
  opacity: 0;
}

.smooth-enter-active, .smooth-leave-active {
  transition: opacity .5s;
}
.smooth-enter, .smooth-leave-to {
  opacity: 0
}
</style>
