<template>
  <div v-if="post != undefined" class="text-left" :class="{'lg:px-48': passedMd === undefined}">
    <Markdown class="mx-5" :breaks="true" :source="post.body"></Markdown>
  </div>
</template>

<script>
import Markdown from 'vue3-markdown-it';
import 'highlight.js/styles/monokai.css';

export default {
  name: "allPost",
  components: {
    Markdown,
  },
  props: {
    passedMd: String
  },
  data() {
    return {
      postidparam: (new URLSearchParams(window.location.search)).get('postid'),
      post: undefined,
      loading: false,
      errored: false
    };
  },
  mounted() {
    if (this.passedMd != undefined) {
      this.post.body = this.passedMd
    } else {
      this.getPost()
    }
    setTimeout(() => { this.manageStyle() }, 10);
  },
  methods: {
    getPost() {
      this.loading = true;
      this.$axios
        .get(`${this.$root.selectedServerDomain}/getPostById?id=${this.postidparam}`, { headers: { "Bypass-Tunnel-Reminder": 2 } })
        .then((response) => {
          this.post = response.data.post;
          this.loading = false;
          document.title = `${this.post.title} | piovonoofferte.it`;
          setTimeout(() => { this.manageStyle() }, 10);
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => { this.loading = false });
    },
    manageStyle() {
      // Select the parent div to scope the styles
      const parentDiv = this.$el;

      // Styling for h1
      parentDiv.querySelectorAll('h1').forEach(h1 => {
        h1.style.fontSize = '28px';
        h1.style.marginTop = '20px';
        h1.style.marginBottom = '20px';
        h1.classList.add('font-bold');
        const borderDiv = document.createElement('div');
        borderDiv.classList.add('border-b', 'border-gray-300', 'pb-2');
        h1.appendChild(borderDiv);
      });

      // Styling for h2
      parentDiv.querySelectorAll('h2').forEach(h2 => {
        h2.style.fontSize = '24px';
        h2.style.marginTop = '15px';
        h2.style.marginBottom = '15px';
        h2.classList.add('font-semibold');
        h2.style.color = '#333';
      });

      // Styling for h3
      parentDiv.querySelectorAll('h3').forEach(h3 => {
        h3.style.fontSize = '20px';
        h3.style.marginTop = '18px';
        h3.style.marginBottom = '10px';
        h3.classList.add('font-semibold');
      });

      // Styling for img
      parentDiv.querySelectorAll('img').forEach(img => {
        const newDiv = document.createElement('div');
        newDiv.classList.add('imagecenter', 'flex', 'items-center', 'justify-center', 'mb-4');
        const newImg = img.cloneNode(true);
        newImg.style.maxHeight = '350px';
        newImg.style.objectFit = 'contain';
        newDiv.appendChild(newImg);
        img.parentNode.replaceChild(newDiv, img);
      });

      // Styling for paragraphs
      parentDiv.querySelectorAll('p').forEach(paragraph => {
        paragraph.style.marginBottom = '12px';
        paragraph.style.lineHeight = '1.8';
        paragraph.style.color = '#555'; // Soft text color
        paragraph.classList.add('text-base');
      });

      // Styling for lists
      parentDiv.querySelectorAll('ul').forEach(ul => {
        ul.style.listStyleType = 'disc'; 
        ul.style.marginLeft = '20px';
        ul.style.marginBottom = '12px';
        ul.classList.add('list-inside');
      });

      parentDiv.querySelectorAll('ol').forEach(ol => {
        ol.style.listStyleType = 'decimal';
        ol.style.marginLeft = '20px';
        ol.style.marginBottom = '12px';
        ol.classList.add('list-inside');
      });

      parentDiv.querySelectorAll('li').forEach(li => {
        li.style.marginBottom = '5px';
        li.classList.add('ml-2');
      });

      // Styling for blockquotes
      parentDiv.querySelectorAll('blockquote').forEach(blockquote => {
        blockquote.style.fontStyle = 'italic';
        blockquote.style.borderLeft = '4px solid #ccc';
        blockquote.style.paddingLeft = '15px';
        blockquote.style.margin = '15px 0';
        blockquote.style.color = '#555';
        blockquote.style.backgroundColor = '#f9f9f9';
        blockquote.style.borderRadius = '5px';
        blockquote.style.padding = '10px 15px';
      });

      parentDiv.querySelectorAll('a').forEach(anchor => {
        anchor.style.color = 'blue';       // Set link color to blue
        anchor.style.textDecoration = 'underline'; // Add underline
      });

      // Implementing product tags with iframes
      parentDiv.querySelectorAll('p').forEach(paragraph => {
        if (paragraph.textContent.match(/^PIOVONOOFFERTETAG-PRODUCT-/)) {
          paragraph.innerHTML = `<iframe class="mt-4" frameborder="0" height="250" width="100%" allowfullscreen src="/productcard?asin=${paragraph.textContent.replace("PIOVONOOFFERTETAG-PRODUCT-", "")}&smalltitle=true"></iframe>`;
        }
      });
    }
  }
}
</script>

<style>
.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.imagecenter {
  margin: 20px 0; /* Add margin for spacing */
}
</style>
