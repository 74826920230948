<template>
  <div class="flex">
    <div class="w-1/2 p-4">
      <h2 class="text-2xl mb-4">Scrivi in Markdown</h2>
      <textarea
        v-model="inputText"
        class="border p-2 w-full h-48"
        @input="inputF()"
      ></textarea>
      <div class="flex items-center mt-4">
        <hr class="flex-grow border-gray-300" />
        <button
          @click="submitArticle"
          :disabled="isLoading"
          class="ml-4 bg-blue-500 text-white py-2 px-4 rounded flex items-center"
        >
          <span v-if="isLoading" class="loader"></span>
          <span v-if="!isLoading">{{ buttonText }}</span>
        </button>
      </div>
      <p class="mt-2 text-gray-600">Fai generare e caricare un articolo da Gemini.</p>
      <div v-if="loadingMessage" class="mt-4 text-gray-800">{{ loadingMessage }}</div>
    </div>
    <div class="w-1/2 p-4">
      <h2 class="text-2xl mb-4">Markdown Elaborato</h2>
      <singlepost :passedMd="inputText" :key="inputText" />
    </div>
  </div>
</template>

<script>
import singlepost from '../blog/singlepost.vue'
import axios from 'axios'; // Make sure to install axios with npm/yarn

export default {
  data() {
    return {
      inputText: '',
      isLoading: false,
      loadingMessage: '',
      buttonText: 'Invia Articolo',
    };
  },
  name: 'mdEditor',
  components: {
    singlepost
  },
  methods: {
    inputF() {
      console.log(this.inputText);
    },
    async submitArticle() {
      this.isLoading = true;
      this.loadingMessage = 'Invio in corso...';
      this.buttonText = 'Inviando...'; // Change button text while loading

      try {
        const response = await axios.get('https://api.piovonoofferte.it/writeanarticlewithgemini', {
          params: {
            markdown: this.inputText,
          },
        });

        // Process the response as needed
        this.loadingMessage = `Articolo inviato con successo: ${response.data.message || 'Nessun messaggio disponibile.'}`;
      } catch (error) {
        this.loadingMessage = `Errore nell'invio: ${error.message || 'Errore sconosciuto.'}`;
      } finally {
        this.isLoading = false;
        this.buttonText = 'Invia Articolo'; // Reset button text
      }
    }
  }
};
</script>

<style scoped>
.loader {
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: white; /* Color of the loading animation */
  width: 16px; /* Size of the loader */
  height: 16px; /* Size of the loader */
  animation: spin 1s linear infinite;
  margin-right: 8px; /* Space between the loader and the text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Stili opzionali per personalizzare il componente di gestione del markdown */
</style>
